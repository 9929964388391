<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- banner -->
    <!-- <div class="banner_box"> -->
    <!-- <img class="banner" :src="this.url.imgUrl + bannerImg" alt="勤学教育" /> -->
    <!-- </div> -->
    <div id="high"></div>
    <!-- 专业导航栏 -->
    <div class="major_nav_box" id="fixPara">
      <!-- 一级导航 -->
      <div class="one_major_nav">
        <div class="major_name" v-for="item in one_bar" :key="item.id" @click="cutSubject(item.course_category_id)">
          <div :class="[is_one == item.course_category_id ? 'major_color' : '']">
            {{ item.name }}
          </div>
          <!-- </div> -->
          <div></div>
        </div>
      </div>
      <!-- 二级导航 -->
      <div class="two_major_nav">
        <div class="h2">选择科目</div>
        <div class="two_major_nav_box" style="margin:9px 0 20px">
          <div class="subject_name" v-for="(el, index) in newArr" :key="index" @click="jump(el.course_category_id)">
            <div :class="[is_two == el.course_category_id ? 'subject_border' : '']">
              <!-- <div style="padding:17px 0 0"> -->
              {{ el.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 版心 -->
    <div id="box" style="height: 250px;display: none;"></div>
    <div id="content">
      <!-- 科目标题 -->
      <div class="subject_box" v-for="item in newArr" :key="item.course_category_id" :id="item.course_category_id">
        <!-- 科目标题 -->
        <div class="subject_title">{{ item.name }}</div>
        <!-- 课程列表 -->
        <div class="course_list_box">
          <div class="course_item_box" v-for="(el, index) in item.video_list" :key="index">
            <!-- @click="goBlank(el.id)" -->
            <router-link :to="{
              name: 'AuditionInfo',
              params: { is_pageid: 3, info_id: el.id },
            }" target="_blank">
              <div class="course_item_item">
                <span>{{ el.name }}</span>
                <p>高清课件</p>
                <div class="start_play">
                  <div class="paly_icon"></div>
                  立即播放
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- 版心结尾 -->
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      bannerImg: "/assets/img/course.jpg",
      one_bar: [], //一级导航
      two_bar: {}, //二级导航
      is_one: "", // 是否选中一级导航
      is_two: "", // 是否选中一级导航
      try_course: [], // 试听课程
      video_cate_list: {},
      course: [], // 课程
      newArr: [],
      active: 0,
      isFixed: false,
      offsetTop: 0,
    };
  },
  methods: {
    initHeight() {
      window.onscroll = function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        var bignav = document.getElementById("fixPara"); //获取到导航栏id
        var box = document.getElementById("box"); //获取到导航栏id
        // console.log(scrollTop)
        if (scrollTop > 100) {
          bignav.style.position = "fixed";
          bignav.style.top = "-20px";
          bignav.style.left = "50%";
          bignav.style.transform = "translateX(-50%)";
          box.style.display = "block";
          // content.style.display = "block";
        } else {
          box.style.display = "none";
          bignav.style.position = "relative";
          bignav.style.top = "0";
          bignav.style.left = "0";
          bignav.style.transform = "none";
          // content.style.display = "none";
        }
      };
    },
    getId(itemId) {
      return itemId;
    },
    // 点击导航栏跳转到固定位置
    jump(domId) {
      this.is_two = domId;
      // 当前窗口正中心位置到指定dom位置的距离

      //页面滚动了的距离
      let height =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      //指定dom到页面顶端的距离
      let dom = document.getElementById(domId);
      let domHeight = dom.offsetTop - 180;

      //滚动距离计算
      var S = Number(height) - Number(domHeight);

      //判断上滚还是下滚
      if (S < 0) {
        //下滚
        S = Math.abs(S);
        window.scrollBy({
          top: S,
          behavior: "smooth",
        });
      } else if (S == 0) {
        //不滚
        window.scrollBy({
          top: 0,
          behavior: "smooth",
        });
      } else {
        //上滚
        S = -S;
        window.scrollBy({
          top: S,
          behavior: "smooth",
        });
      }
    },
    getOneNav() {
      // 一级导航接口
      var data = {
        pid: 1235,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.one_bar = res.data.data;
          if (this.$route.query.type) {
            switch (Number(this.$route.query.type)) {
              case 1:
                this.is_one = 1236;
                this.getTwoNav(1236); //一建
                break;
              case 2:
                this.is_one = 1237;
                this.getTwoNav(1237); //二建
                break;
              case 3:
                this.is_one = 1238;
                this.getTwoNav(1238); //安全
                break;
              // case 4:
              //   this.is_one = 1239;
              //   this.getTwoNav(1239); //药师
              //   break;
              case 5:
                this.is_one = 1296;
                this.getTwoNav(1296); //监理
                break;
            }
          } else {
            this.is_one = res.data.data[0].course_category_id;
            this.getTwoNav(res.data.data[0].course_category_id);
          }
        }
      });
    },
    // 二级导航
    getTwoNav(id) {
      this.video_cate_list = {};
      var data = {
        pid: id,
      };
      api.course_cate(data).then((res) => {
        if (parseInt(res.data.code) == 1) {
          this.getCourse(id);
          // var video_cate_list = [];
          this.two_bar = res.data.data;
          this.course = res.data.data;
          this.is_two = res.data.data[0].course_category_id;
        }
      });
    },

    // 试听课程接口
    getCourse(course_category_id) {
      var data = {
        course_category_id: course_category_id,
        type: 2,
        page_size: 100,
      };
      api.courses(data).then((res) => {
        this.newArr = [];
        if (parseInt(res.data.code) === 1) {
          this.course.forEach((el) => {
            el.video_list = [];
            res.data.data.list.map(function (item) {
              if (el.course_category_id == item.course_category_id) {
                if (item) {
                  item.name = item.name.replace(
                    /^试听[- ]+([^- ])(.*)/,
                    "$1$2"
                  );
                  el.video_list.push(item);
                } else {
                }
              }
            });
            this.newArr.push(el);
            this.$forceUpdate();
          });
          this.newArr = this.newArr.filter(
            (item) => item.video_list.length !== 0
          );
        }
      });
    },
    // 切换专业科目
    cutSubject(id) {
      this.getTwoNav(id);
      this.is_one = id;
      // this.this.getCourse(id)
    },
    // 去试听课程详情页
    goTryClassInfo(id) {
      this.$router.push({
        name: "AuditionInfo",
        params: { is_pageid: 3, info_id: id },
      });
    },
  },
  created() {
    this.getOneNav();
    this.initHeight();
    // this.video_cate_list = this.two_bar
  },
  destroyed() {
    window.onscroll = ''
  }
};
</script>

<style lang="scss" scoped>
/* #app {
    height: 100vh;
} */
#app {
  background: #f5f5f5;
}

.banner {
  width: 100%;
  height: 400px;
}

/* 专业导航栏 */

.major_nav_box {
  width: 1200px;
  border-radius: 6px;
  /* height: 140px; */
  max-height: 174px;
  background: #fff;
  // box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  // position: sticky;
  // top: 0;
  // left: 0;
  margin: 20px auto 30px;
  // right: 0;
}

/* 一级导航 */

.one_major_nav {
  position: relative;
  width: 1200px;
  height: 70px;
  margin: auto;
  /* background: pink; */
  display: flex;
  align-items: center;
  // border-left: 1px solid #eee;
  // border-right: 1px solid #eee;
}

.one_major_nav::after {
  content: "";
  width: 1160px;
  height: 1px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #f5f5f5;
}

/* 专业名字 */

.major_name {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #202b3a;
  display: flex;
  flex-flow: column;
  justify-content: center;
  // align-items: center;
}

.h2 {
  width: 80px;
  font-size: 14px;
  font-weight: 400;
  color: #484848;
  margin-right: 50px;
  padding-top: 23px;
}

.major_color {
  color: #fff;
  // padding: 6px 20px;
  border-radius: 6px;
  /* background: #3e7eff26; */
  background: #3e7eff;
  border-radius: 20px;
}

.major_name div {
  padding: 5px 20px;
}

/*  */
.major_name div:nth-child(2) {
  position: absolute;
  top: 51px;
  bottom: 0;
}

.major_name div img {
  // position: absolute;
  // // left: 0;
  // // right: 0;
  // bottom: 0;
  width: 20px;
  height: 10px;
}

/* 二级导航 */

.two_major_nav {
  width: 1180px;
  /* height: 70px; */
  margin: auto;
  /* background: skyblue; */
  display: flex;
  // align-items: center;
  padding: 0 30px;
  // border: 1px solid #3e7eff;
}

.two_major_nav .two_major_nav_box {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  align-items: center;
  /* align-content: flex-start;    */
  margin: 23px 0 20px;
  /* background: pink; */
}

.subject_name {
  /* height: 21px; */
  margin-right: 50px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #202b3a;
  cursor: pointer;
  padding: 0;
  /* padding: 3px 12px; */
  /* padding: 28px 0 18px; */
}

.subject_name div {
  padding: 14px 0 0px;
}

.subject_name .subject_border {
  color: #3e7eff;
  /* padding: 7px 12px; */
  // background: #e9f2ff;
  border-radius: 20px;
}

/* 版心 */

#content {
  width: 1200px;
  /* height: 500px; */
  /* background: pink; */
  margin: auto;
  overflow: hidden;
}

/* 科目标题 */

.subject_box {
  width: 1140px;
  background: #fff;
  border-radius: 6px;
  padding: 20px 30px;
  margin-bottom: 20px;
}

#content .subject_box:last-child {
  margin-bottom: 60px;
}

.subject_box .subject_title {
  margin: 0 24px 20px;
}

.subject_title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #000b2f;
}

/* 课程列表 */

.course_list_box {
  // width: 1190px;
  height: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  // box-shadow: 0 9px 12px 0 rgba(0, 0, 0, 0.05);

  // box-shadow: 0px 9px 12px 0 rgba(0 0 0 / 0.1);
}

.course_item_box {
  width: 100%;
  cursor: pointer;
  border-top: 1px solid #F5F5F5;
  /* display: flex; */
}

.subject_name:hover {
  color: #3e7eff;
}

.course_item_box a {
  color: #202b3a;
}

.course_item_box a:hover {
  color: #3e7eff;
  font-weight: 400;
  background: #e9f2ff;
}

.course_item_item {
  // width: 1100px;
  height: 70px;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
}

.course_item_box a {
  display: flex;
}

.course_item_box:hover .course_item_item {
  font-weight: 500;
}

.course_item_item span {
  width: 490px;
}

.course_item_item p {
  margin: 0 141px 0 296px;
}

.start_play {
  display: flex;
  align-items: center;
}

/* 播放按钮 */

.paly_icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  background-image: url(../../assets/img/bofang_normal.png);
}

.course_item_box:hover .paly_icon {
  background-image: url(../../assets/img/bofang_hover.png);
}

.isFixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

#high {
  display: none;
  height: 150px;
}
</style>
